.questionMain {
    display: flex;
    gap: 11px;
    width: 100%;
    margin-bottom: 8px;
    cursor: pointer;
    align-items: center;
}

.question {
    font-style: normal !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    width: 88%;
}

.arrowFaq {
    width: 5%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.sound {
    width: 33px;
    cursor: pointer;
    color: #E8345E;
    border: 1px solid #E8345E;
    border-radius: 50%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.answer {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #333333;
    width: 88%;
    margin-top: 20px;
}

.borderDivider {
    background-color: #000;
    width: 85%;
    margin: 24px 0px;
    border: 1px solid black;
}

@media (max-width: 600px) {
    .question {
        font-size: 16px;
        line-height: 24px;
    }

    .sound {
        width: 33px;
        height: 30px;
    }

    .answer {
        font-size: 14px;
        line-height: 20px;
    }
}