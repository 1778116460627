.mainSection{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
    gap: 20px;
}

.sectionHeading{
    font-size: 20px;
    font-weight: 600;
}

.categories{    
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.categoryImageContainer{
    font-size: 12px;
    border-radius: 3.04px;
    padding: 9px 10px 9px 10px;
    box-shadow: 0px 0px 4px 0px #00000040;
    width: 90px;
    height: 90px;
    display: flex;          
    justify-content: center;  
    align-items: center;  
    cursor: pointer;
}

.category{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.categoryTextContainer{
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoryText{
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

.selectedCategory{
    border: 1px solid #EE2737
}
