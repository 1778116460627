.checkemiMainContainer {
    background-color: #FCE5D4;
    width: 100%;
    border-radius: 10px;
    padding: 20px 60px 20px 60px;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
}

.interestRateContainer {
    display: flex;
    justify-content: center;
}

.interestRateSubContainer {
    margin: 0px 40px;
}

.value {
    font-size: 32px;
    font-weight: 700;
}

.title {
    font-size: 24px;
    font-weight: 550;
    opacity: 0.8;
}

.Subtitle {
    font-size: 20px;
    opacity: 0.7;
}

.buttonEmi {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    color: white;
    background-color: #E8345E;
    border: none;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    margin: 20px 0px;
}

.imageContainer {
    display: flex;
    justify-Content: space-between;
}

.tcTextContainer {
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    opacity: 0.6;
    padding: 5px 0px;
}

.header {
    font-size: 34px;
    font-Weight: 550;
    opacity: 0.7;
    letter-spacing: 1.2;
}

@media (max-width: 600px) {
    .checkemiMainContainer {
        background-color: #FCE5D4;
        width: 100%;
        border-radius: 10px;
        padding: 20px 20px 0px 20px;
    }

    .bottomContainer {
        display: flex;
        flex-direction: column;
    }

    .interestRateContainer {
        display: flex;
        justify-content: center;
    }

    .interestRateSubContainer {
        margin: 0px 12px;
    }

    .value {
        font-size: 20px;
        font-weight: 700;
    }

    .title {
        font-size: 12px;
        font-weight: 550;
        opacity: 0.8;
    }

    .Subtitle {
        font-size: 10px;
        opacity: 0.7;
    }

    .buttonEmi {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        color: white;
        background-color: #E8345E;
        border: none;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: center;
        margin: 10px 0px;
    }

    .imageContainer {
        display: flex;
        justify-content: space-between;
    }

    .tcTextContainer {
        font-size: 10px;
        font-weight: 300;
        font-style: italic;
        opacity: 0.6;
        padding: 5px 0px;
    }

    .header {
        font-size: 24px;
        font-weight: 600;
        opacity: 1;
        color: #E8345E;
        text-align: center;
        letter-spacing: 1.2px;
        line-height: 1.2;
    }
}
